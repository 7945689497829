export const wrongInputs = "Wrong Inputs";
export const hoursInputRegex = /^[0-6]$/;
export const everyDay = "every day";
export const timeFormat = "UTC";
export const awsCronDefaultStart = "Run";
export const dayMapping = {
  SUN: "Sunday",
  MON: "Monday",
  TUE: "Tuesday",
  WED: "Wednesday",
  THU: "Thursday",
  FRI: "Friday",
  SAT: "Saturday",
};

export const days = {
  1: "SUN",
  2: "MON",
  3: "TUE",
  4: "WED",
  5: "THU",
  6: "FRI",
  7: "SAT",
};

export const monthMapping = {
  1: "JAN",
  2: "FEB",
  3: "MAR",
  4: "APR",
  5: "MAY",
  6: "JUN",
  7: "JUL",
  8: "AUG",
  9: "SEP",
  10: "OCT",
  11: "NOV",
  12: "DEC",
};

export const monthName = {
  JAN: "January",
  FEB: "Febuary",
  MAR: "March",
  APR: "April",
  MAY: "May",
  JUN: "June",
  JUL: "July",
  AUG: "August",
  SEP: "September",
  OCT: "October",
  NOV: "November",
  DEC: "December",
};

export const wildCards = [",", "-", "*", "?", "/", "L", "W", "#"];

export const cronTypes = [
  { value: "aws_cron", label: "AWS CloudWatch" },
  { value: "unix_cron", label: "UNIX Cron" },
  { value: "google_cron", label: "Google Cloud Scheduler" },
];

export const awsCronWildCards = [
  "minutes",
  "hour",
  "day(month)",
  "month",
  "day(week)",
  "year",
];
export const unixCronWildCards = [
  "minutes",
  "hour",
  "day(month)",
  "month",
  "day(week)",
];

export const awsDetails = {
  syntax:
    "Cron expressions have six required fields, which are separated by white space.",
  fields: [
    {
      field: "Minutes",
      value: "0-59",
      wildCards: ", - * /",
    },
    {
      field: "Hours",
      value: "0-59",
      wildCards: ", - * /",
    },
    {
      field: "Day-of-month",
      value: "1-31",
      wildCards: ", - * ? / L W",
    },
    {
      field: "Month",
      value: "1-12 or JAN-DEC",
      wildCards: ", - * /",
    },
    {
      field: "Day-of-week",
      value: "1-7 or SUN-SAT",
      wildCards: ", - * ? L #",
    },
    {
      field: "Year",
      value: "1970-2199",
      wildCards: ", - * /",
    },
  ],
};

export const unixCronDetails = {
  syntax:
    "Cron expressions have five required fields, which are separated by white space.",
};


const months = Object.keys(monthName);
const weeks = Object.keys(dayMapping);

export const randomMinute = [
  `${getRandom(0, 59)}`,
  `${getRandom(0, 59)}/${getRandom(0, 59)}`,
  `${rangeGenerator(getRandom(0, 59),getRandom(0, 59))}`,
  `${getRandom(0, 59)},${getRandom(0, 59)}`,
  "*",
];
export const randomHours = [
  `${getRandom(0, 23)}`,
  `${getRandom(0, 23)}/${getRandom(0, 23)}`,
  `${rangeGenerator(getRandom(0, 23),getRandom(0, 23))}`,
  `${getRandom(0, 23)},${getRandom(0, 23)}`,
  "*",
];
export const randomDayOfMonth = [
  `${getRandom(1, 31)}`,
  `${getRandom(1, 31)}/${getRandom(1, 31)}`,
  `${rangeGenerator(getRandom(1, 31),getRandom(1, 31))}`,
  `${getRandom(1, 31)},${getRandom(1, 31)}`,
  "*",
  "?",
];
export const randomMonth = [
  `${getRandom(1, 12)}`,
  `${getRandom(1, 12)}/${getRandom(1, 12)}`,
  `${rangeGenerator(getRandom(1, 12),getRandom(1, 12))}`,
  `${getRandom(1, 12)},${getRandom(1, 12)}`,
  `${months[getRandom(0, 11)]}`,
  `${months[getRandom(0, 11)]}/${months[getRandom(0, 11)]}`,
  `${months[getRandom(0, 11)]},${months[getRandom(0, 11)]}`,
  "*",
];

export const randomDayOfWeek = [
  `${getRandom(1, 7)}`,
  `${getRandom(1, 7)}/${getRandom(1, 7)}`,
  `${rangeGenerator(getRandom(1, 7),getRandom(1, 7))}`,
  `${getRandom(1, 7)},${getRandom(1, 7)}`,
  `${weeks[getRandom(0, 6)]}`,
  `${weeks[getRandom(0, 6)]}/${weeks[getRandom(0, 6)]}`,
  `${stringFieldRangeGenerator(weeks[getRandom(0, 6)], weeks[getRandom(0, 6)], days)}`,
  `${weeks[getRandom(0, 6)]},${weeks[getRandom(0, 6)]}`,
  "*",
  "?",
];

export const randomYear = [
  `${getRandom(1970, 2199)}`,
  `${rangeGenerator(getRandom(1970, 2199),getRandom(1970, 2199))}`,
  `${getRandom(1970, 2199)},${getRandom(1970, 2199)}`,
  "*",
];

function getRandom(min, max) {
  return min + Math.floor(Math.random() * new Array(max - min + 1).length);
}

function rangeGenerator(random1, random2){
  return random1 < random2 ? `${random1}-${random2}` : `${random2}-${random1}`
}

function stringFieldRangeGenerator(random1, random2, mapper){
  let moIndex = Object.keys(mapper).find(key => mapper[key] === random1)
  let m1Index = Object.keys(mapper).find(key => mapper[key] === random2)
 
  return moIndex < m1Index ? `${random1}-${random2}` : `${random2}-${random1}`
}

export const syntaxDetails = {
  minutes: {
    "*": "any value",
    ",": "value list separator",
    "-": "range separator",
    "/": "step values",
    "0-59": "allowed values",
  },

  hours: {
    "*": "any value",
    ",": "value list separator",
    "-": "range separator",
    "/": "step values",
    "0-59": "allowed values",
  },

  dayOfMonth: {
    "*": "any value",
    ",": "value list separator",
    "-": "range separator",
    "/": "step values",
    "?": "no specific value",
    "1-31": "allowed values",
  },

  month: {
    "*": "any value",
    ",": "value list separator",
    "-": "range separator",
    "/": "step values",
    "1-12": "allowed values",
    "JAN-DEC": "alt allowed values",
  },

  dayOfWeek: {
    "*": "any value",
    ",": "value list separator",
    "-": "range separator",
    "/": "step values",
    "?": "no specific value",
    "1-7/SUN_SAT": "allowed values",
  },

  year: {
    "*": "any value",
    ",": "value list separator",
    "-": "range separator",
    "/": "step values",
    "1970-2199": "allowed values",
  },

  default: {
    "*": "any value",
    ",": "value list separator",
    "-": "range separator",
    "/": "step values",
    "?": "no specific value",
  },
};

export const cronSyntaxDetails = [
  {
    aws_cron: {
      minutes: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "0-59": "allowed values",
      },

      hours: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "0-59": "allowed values",
      },

      dayOfMonth: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "?": "no specific value",
        "1-31": "allowed values",
      },

      month: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "1-12": "allowed values",
        "JAN-DEC": "alternate allowed values",
      },

      dayOfWeek: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "?": "no specific value",
        "1-7/SUN_SAT": "allowed values",
      },

      year: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "1970-2199": "allowed values",
      },

      default: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "?": "no specific value",
      },
    },
    unix_cron: {
      minutes: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "0-59": "allowed values",
      },

      hours: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "0-59": "allowed values",
      },

      dayOfMonth: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "1-31": "allowed values",
      },

      month: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "1-12": "allowed values",
        "JAN-DEC": "alternate allowed values",
      },

      dayOfWeek: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "1-7": "allowed values",
        SUN_SAT: "alternate allowed values",
      },

      year: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "1970-2199": "allowed values",
      },

      default: {
        "*": "any value",
        ",": "value list separator",
        "-": "range separator",
        "/": "step values",
        "?": "no specific value",
      },
    },
  },
];

export const wildCardsTypes = [
  "minutes",
  "hours",
  "dayOfMonth",
  "month",
  "dayOfWeek",
  "year",
];

export const designWildCardsTypes = {
  minutes: "Minutes",
  hours: "Hours",
  dayOfMonth: "Day(month)",
  month: "Month",
  dayOfWeek: "Day(week)",
  year: "Year",
};

export const defaultInput = "10 12 ? 12 1-3 2004";
