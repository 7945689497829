import React from "react";
import { syntaxDetails } from "../constants.js";
class CronInfo extends React.Component {
  render() {
    const { syntax } = this.props;

    const capitalizeString = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
      <div>
        <div className="syntax-info-container">
          {Object.keys(syntaxDetails[syntax]).map(function (key, index) {
            return (
              <div className="cron-info" key={key+index} value={index}>
                <div className="tag">
                  <span>{key}</span>
                </div>
                <div className="meaning">
                  <div>
                    <span>{capitalizeString(syntaxDetails[syntax][key])}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default CronInfo;
