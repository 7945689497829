import React from "react";
import * as convertor from "../parser/convertor.js";
import { wildCardsTypes, defaultInput, cronTypes } from "../constants.js";
import CronTitle from "./CronTitle";
import CronInput from "./CronInput";
import CronInfo from "./CronInfo";
import CronOutput from "./CronOutput";
import SocialLinks from "./SocialLinks";

class CronConsole extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCronOption: { value: "unix_cron", label: "UNIX Cron" },
      cronResult: "",
      errors: "",
      cronInput: defaultInput,
      currentCurser: "",
      activeKeySyntax: "default",
    };
  }

  componentDidUpdate(prev, next) {}

  componentWillReceiveProps() {}

  componentDidMount() {
    const { location } = this.props;
    let type = location.search.split("=")[1];
    let cloudWatch = cronTypes.find((cronType) => cronType.value === type);

    if (location && location.hash.length > 0 && cloudWatch) {
      let cronExp = location.hash
        .replace("#", "")
        .split("_")
        .join(" ")
        .toString();
      this.setState({
        cronInput: cronExp,
        selectedCronOption: cloudWatch,
      });
      this.runCron(cronExp);
    } else {
      this.setState({
        cronInput: defaultInput,
      });
      this.runCron(defaultInput);
    }
  }

  handleChange = (selectedCronOption) => {
    this.setState({
      selectedCronOption,
    });

    let input = document.getElementById("cron-input");

    var result = convertor.translate({
      cronType: selectedCronOption.value,
      cronInput: input.value,
    });

    this.setCronResult(result);
    this.handleQueryParameters(this.state.cronInput, selectedCronOption);
  };

  onKeyUpInput = (e) => {
    let self = this;
    let curPos = e.target.selectionStart;
    let str = this.state.cronInput.replace(/\s+/g, " ").trim();
    self.handleCronInputSyntax(str, curPos);
  };

  handleQueryParameters = (cronExp, cronOption = "") => {
    const { selectedCronOption } = this.state;
    const option = cronOption.value ? cronOption : selectedCronOption;
    this.props.history.push("");
    this.props.history.push(
      `/?cron_type=${option.value}#${cronExp.split(" ").join("_")}`
    );
  };

  splitOn = (slicable, ...indices) =>
    [0, ...indices].map((n, i, m) => slicable.slice(n, m[i + 1]));

  handleCronInput = (e) => {
    let self = this;
    const { value } = e.target;
    const expression = value.replace(/\s+/g, " ").trim();

    expression.split(" ").length === 6
      ? this.setState({ cronInput: expression })
      : this.setState({ cronInput: value });

    this.runCron(expression);
    let curPos = e.target.selectionStart;
    self.handleCronInputSyntax(expression, curPos);
    self.handleQueryParameters(expression);
  };

  handleCronInputSyntax = (str, curPos) => {
    let self = this;
    let currentIndex =
      str.replace(/\s+/g, " ").trim().split(" ").length -
      self.splitOn(str, curPos)[1].split(" ").length;
    this.setState({ activeKeySyntax: wildCardsTypes[currentIndex] });
  };

  setActiveKeySyntax = (syntax) => {
    this.setState({ activeKeySyntax: syntax });
  };

  runCron = (input) => {
    const { selectedCronOption } = this.state;
    var result = convertor.translate({
      cronType: selectedCronOption.value,
      cronInput: input,
    });

    this.setCronResult(result);
  };

  setCronResult = (result) => {
    if (result) {
      if (result.errors && result.errors.length > 0) {
        this.setState({ errors: result.errors, cronResult: "" });
      } else if (result.output && result.output.length > 0) {
        this.setState({ cronResult: result.output, errors: "" });
      }
    }
  };

  setRandomExp = (randomExp) => {
    this.setState({ cronInput: randomExp });
    this.runCron(randomExp);
    this.handleQueryParameters(randomExp);
  };

  render() {
    const { selectedCronOption, cronResult, cronInput, errors } = this.state;

    return (
      <div>
        <div className="component-container">
          <CronTitle />

          <CronInput
            selectValue={selectedCronOption}
            cronInputHandler={this.handleChange}
            onChangeHandler={this.handleCronInput}
            onKeyUpHandler={this.onKeyUpInput}
            inputValue={cronInput}
            syntax={this.state.activeKeySyntax}
            randomExpHandler={this.setRandomExp}
            setActiveKeySyntax={this.setActiveKeySyntax}
            errors={errors}
            runCron={this.runCron}
          />
          <CronOutput result={cronResult} errors={errors} />

          <CronInfo syntax={this.state.activeKeySyntax} />

          <SocialLinks />
        </div>
      </div>
    );
  }
}

export default CronConsole;
