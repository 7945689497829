import { wildCards, monthMapping, days } from "../constants.js";
import { FieldConvertor as fieldConvertor } from "./fieldConvertor";
import { FieldValidator as fieldValidator } from "./fieldValidator";
const expressions = ["*", "?"];
export default class CronParser {
  constructor(cronInput, type) {
    this.errors = [];
    this.cronInput = cronInput;
    this.cronInputArray = cronInput.split(" ");
    this.parserType = type;
  }

  getErrors() {
    return [...new Set(this.errors)].join(", ");
  }

  pushErrors(err) {
    this.errors.push(err);
  }

  parseMinutes() {
    let sentence = [];
    let cronInputArray = this.cronInputArray;
    let minute = cronInputArray[0];
    let hour = cronInputArray[1];
    let self = this;

    if (minute === "*") {
      sentence.push("every minute");
    } else if (minute.includes("/")) {
      let minutes = minute.split("/");

      if (
        (minutes[0] === "0" || minutes[0] === "*") &&
        fieldValidator.validateMinute(minutes[1])
      ) {
        sentence.push(`every ${minutes[1]} minute`);
      } else if (fieldValidator.validateBothMinutes(minutes)) {
        sentence.push(
          `every ${minutes[1]} minute starting from ${minutes[0]} minutes`
        );
      } else {
        self.pushErrors("invalid minutes");
      }
    } else if (minute.includes("-")) {
      let minutes = minute.split("-");

      if (
        fieldValidator.validateBothMinutes(minutes) &&
        fieldValidator.validateHours(hour) &&
        self.validateRange(minutes)
      ) {
        sentence.push(
          `every minute between ${fieldConvertor.convertHour(
            hour,
            minutes[0]
          )} and ${fieldConvertor.convertHour(hour, minutes[1])}`
        );
      } else if (fieldValidator.validateBothMinutes(minutes)) {
        sentence.push(`minutes ${minutes[0]} through ${minutes[1]}`);
      } else {
        self.pushErrors("invalid minutes");
      }
    } else if (minute.includes(",")) {
      let minutes = minute.split(",");

      if (!fieldValidator.validateHours(hour)) {
        sentence.push("at");

        minutes.forEach(function (element, index) {
          if (
            fieldValidator.validateMinute(element) &&
            index < minutes.length - 2
          ) {
            sentence.push(`${element},`);
          } else if (
            fieldValidator.validateMinute(element) &&
            index < minutes.length - 1
          ) {
            sentence.push(`${element}`);
          } else if (
            fieldValidator.validateMinute(element) &&
            index === minutes.length - 1
          ) {
            sentence.push(`and ${element} minutes`);
          } else {
            self.pushErrors("invalid minutes");
          }
        });
      }
    } else if (fieldValidator.validateMinute(minute) && hour === "*") {
      sentence.push(`every ${minute} minute`);
    } else if (
      fieldValidator.validateMinute(minute) &&
      hour.split("").some((element) => wildCards.includes(element))
    ) {
      sentence.push(`at ${minute} minutes`);
    }

    return sentence.join(" ");
  }

  validateRange(values) {
    return parseInt(values[0]) <= parseInt(values[1])
  }

  parseHours() {
    let sentence = [];
    let cronInputArray = this.cronInputArray;
    let minute = cronInputArray[0];
    let hour = cronInputArray[1];
    let self = this;

    if (hour) {
      if (hour.includes("-")) {
        let hours = hour.split("-");
        if (fieldValidator.validateBothHour(hours) && self.validateRange(hours)
        ) {
          sentence.push(
            `past every hour from ${fieldConvertor.convertHour(
              hours[0]
            )} through ${fieldConvertor.convertHour(hours[1])}`
          );
        } else {
          self.pushErrors("invalid hours");
        }
      } else if (hour.includes("/")) {
        let hours = hour.split("/");

        if (fieldValidator.validateBothHour(hours)) {
          sentence.push(
            `past every ${
              hours[1]
            } hour starting from ${fieldConvertor.convertHour(hours[0])}`
          );
        } else if (
          (hours[0] === "0" || hours[0] === "*") &&
          fieldValidator.validateHours(hours[1])
        ) {
          sentence.push(`past every ${hours[1]} hour`);
        } else {
          self.pushErrors("invalid hours");
        }
      } else if (hour.includes(",")) {
        let hours = hour.split(",");
        sentence.push("past every");

        if (fieldValidator.validateMinute(minute)) {
          hours.forEach(function (element, index) {
            if (
              fieldValidator.validateHours(element) &&
              index < hours.length - 2
            ) {
              sentence.push(`${fieldConvertor.convertHour(element, minute)},`);
            } else if (
              fieldValidator.validateHours(element) &&
              index < hours.length - 1
            ) {
              sentence.push(`${fieldConvertor.convertHour(element, minute)}`);
            } else if (
              fieldValidator.validateHours(element) &&
              index === hours.length - 1
            ) {
              sentence.push(
                `and ${fieldConvertor.convertHour(element, minute)}`
              );
            }
          });
        } else if (fieldValidator.havingWildCards(minute)) {
          hours.forEach(function (element, index) {
            if (
              fieldValidator.validateHours(element) &&
              index < hours.length - 2
            ) {
              sentence.push(`${fieldConvertor.convertHour(element)},`);
            } else if (
              fieldValidator.validateHours(element) &&
              index < hours.length - 1
            ) {
              sentence.push(`${fieldConvertor.convertHour(element)}`);
            } else if (
              fieldValidator.validateHours(element) &&
              index === hours.length - 1
            ) {
              sentence.push(`and ${fieldConvertor.convertHour(element)}`);
            }
          });
        }
      } else if (
        fieldValidator.validateMinute(minute) &&
        fieldValidator.validateHours(hour)
      ) {
        sentence.push(`at ${fieldConvertor.convertHour(hour, minute)}`);
      } else if (
        fieldValidator.validateHours(hour) &&
        fieldValidator.havingWildCards(minute) &&
        !minute.includes(",")
      ) {
        sentence.push(`past hour ${fieldConvertor.convertHour(hour)}`);
      } else if (hour === "*") {
      } else if (minute.includes(",")) {
        let minutes = minute.split(",");
        sentence.push("at");

        if (fieldValidator.validateHours(hour)) {
          minutes.forEach(function (element, index) {
            if (
              fieldValidator.validateMinute(element) &&
              index < minutes.length - 2
            ) {
              sentence.push(`${fieldConvertor.convertHour(hour, element)},`);
            } else if (
              fieldValidator.validateMinute(element) &&
              index < minutes.length - 1
            ) {
              sentence.push(`${fieldConvertor.convertHour(hour, element)}`);
            } else if (
              fieldValidator.validateMinute(element) &&
              index === minutes.length - 1
            ) {
              sentence.push(`and ${fieldConvertor.convertHour(hour, element)}`);
            } else {
              self.pushErrors("invalid minutes");
            }
          });
        }
      } else {
        self.pushErrors("invalid hours");
      }
    }

    return sentence.join(" ");
  }

  parseDayOfMonth() {
    let sentence = [];
    let self = this;
    let cronInputArray = this.cronInputArray;
    let minute = cronInputArray[0];
    let hour = cronInputArray[1];
    let dayOfMonths = cronInputArray[2];
    let dayOfWeek = cronInputArray[4];

    if (
      dayOfMonths &&
      fieldValidator.validateDayofWeekAndDayofMonth(
        dayOfWeek,
        dayOfMonths,
        this.parserType
      )
    ) {
      if (dayOfMonths.includes("/")) {
        let dayOfMonth = dayOfMonths.split("/");
        if (
          fieldValidator.validateDayOfMonth(dayOfMonth[0]) &&
          fieldValidator.validateDayOfMonth(dayOfMonth[1])
        ) {
          sentence.push(`every ${fieldConvertor.ordinalSuffixOf(
            parseInt(dayOfMonth[1])
          )} day, \
												 starting on ${fieldConvertor.ordinalSuffixOf(
                           parseInt(dayOfMonth[0])
                         )} day of month`);
        } else if (
          dayOfMonth[0] === "*" &&
          fieldValidator.validateDayOfMonth(dayOfMonth[1])
        ) {
          sentence.push(
            `every ${
              dayOfMonth[1] === "1"
                ? ""
                : fieldConvertor.ordinalSuffixOf(parseInt(dayOfMonth[1]))
            } day of month`
          );
        } else {
          self.pushErrors("invalid day of month");
        }
      } else if (dayOfMonths.includes(",")) {
        let dayOfMonth = dayOfMonths.split(",");
        sentence.push("every");

        dayOfMonth.forEach(function (element, index) {
          if (
            fieldValidator.validateDayOfMonth(element) &&
            index < dayOfMonth.length - 2
          ) {
            sentence.push(
              `${fieldConvertor.ordinalSuffixOf(parseInt(element))},`
            );
          } else if (
            fieldValidator.validateDayOfMonth(element) &&
            index < dayOfMonth.length - 1
          ) {
            sentence.push(
              `${fieldConvertor.ordinalSuffixOf(parseInt(element))}`
            );
          } else if (
            fieldValidator.validateDayOfMonth(element) &&
            index === dayOfMonth.length - 1
          ) {
            sentence.push(
              `and ${fieldConvertor.ordinalSuffixOf(
                parseInt(element)
              )} day of month`
            );
          } else {
            self.pushErrors("invalid day of month");
          }
        });
      } else if (dayOfMonths === "LW") {
        sentence.push("last weekday of the month");
      } else if (dayOfMonths.includes("L")) {
        sentence.push(self.lastDayOfMonth(dayOfMonths));
      } else if (dayOfMonths.includes("-") ) {
        let dayOfMonth = dayOfMonths.split("-");
        if (
          fieldValidator.validateDayOfMonth(dayOfMonth[0]) &&
          fieldValidator.validateDayOfMonth(dayOfMonth[1])&& 
          self.validateRange(dayOfMonth)
        ) {
          sentence.push(`every ${fieldConvertor.ordinalSuffixOf(
            parseInt(dayOfMonth[0])
          )} \
												 day to ${fieldConvertor.ordinalSuffixOf(
                           parseInt(dayOfMonth[1])
                         )} day of month`);
        } else {
          self.pushErrors("invalid day of month");
        }
      } else if (dayOfMonths.includes("W")) {
        // TODO example not found
        let weeks = dayOfMonths.split("");
        if (weeks.length === 2) {
          if (parseInt(weeks[0])) {
            sentence.push(
              `the nearest weekday to the ${fieldConvertor.ordinalSuffixOf(
                parseInt(weeks[0])
              )} of the month`
            );
          }
        } else if (dayOfMonths === "W") {
          sentence.push("only on weekdays");
        } else {
          self.pushErrors("invalid day of month");
        }
      } else if (
        dayOfMonths === "?" &&
        !expressions.includes(hour) &&
        !expressions.includes(minute) &&
        !fieldValidator.havingWildCards(dayOfMonths)
      ) {
        sentence.push("every day of month");
      } else if (fieldValidator.validateDayOfMonth(dayOfMonths)) {
        sentence.push(
          `${fieldConvertor.ordinalSuffixOf(dayOfMonths)} day of the month`
        );
      } else if (dayOfMonths === "*") {
      } else if (dayOfMonths === "?") {
      } else {
        self.pushErrors("invalid day of month");
      }
    } else {
      this.errors.push("Invalid Expression");
    }

    return sentence.join(" ");
  }

  parseMonth() {
    // 1-12 or JAN-DEC
    // , - * /

    let cronInputArray = this.cronInputArray;
    let month = cronInputArray[3];
    let self = this;
    let sentence = [];

    if (month) {
      if (month.includes("/")) {
        let months = month.split("/");

        if (
          fieldValidator.validateMonth(months[0]) &&
          fieldValidator.validateMonth(months[1])
        ) {
          sentence.push(`every ${fieldConvertor.getMonthIndex(
            months[1]
          )} month, ${fieldConvertor.convertToMonth(months[0])}
												 through ${fieldConvertor.convertToMonth("DEC")}`);
        } else if (
          months[0] === "*" &&
          fieldValidator.validateMonth(months[1])
        ) {
          sentence.push(
            `every ${
              months[1] === "1" ? "" : fieldConvertor.convertToMonth(months[1])
            } month`
          );
        } else {
          self.pushErrors("invalid month");
        }
      } else if (month.includes("-")) {
        let months = month.split("-");

        if (
          fieldValidator.validateMonth(months[0]) &&
          fieldValidator.validateMonth(months[1]) &&
          (self.validateRange(self.getRange(months, monthMapping)))
        ) {
          sentence.push(
            `every month from ${fieldConvertor.convertToMonth(
              months[0]
            )} through ${fieldConvertor.convertToMonth(months[1])}`
          );
        } else {
          self.pushErrors("invalid month");
        }
      } else if (month.includes(",")) {
        let months = month.split(",");
        sentence.push("only in");
        months.forEach(function (element, index) {
          if (
            fieldConvertor.convertToMonth(element) &&
            index < months.length - 2
          ) {
            sentence.push(`${fieldConvertor.convertToMonth(element)},`);
          } else if (
            fieldConvertor.convertToMonth(element) &&
            index < months.length - 1
          ) {
            sentence.push(`${fieldConvertor.convertToMonth(element)}`);
          } else if (
            fieldConvertor.convertToMonth(element) &&
            index === months.length - 1
          ) {
            sentence.push(`and ${fieldConvertor.convertToMonth(element)}`);
          } else {
            self.pushErrors("invalid month");
          }
        });
      } else if (fieldValidator.validateMonth(month)) {
        sentence.push(`in ${fieldConvertor.convertToMonth(month)}`);
      } else if (month === "*") {
      } else {
        self.pushErrors("invalid month");
      }
    }

    return sentence.join(" ");
  }

  getRange(field, mapper) {
    if(!(this.isInteger(field[0]) && this.isInteger(field[0]))){
      let moIndex = Object.keys(mapper).find(key => mapper[key] === field[0])
      let m1Index = Object.keys(mapper).find(key => mapper[key] === field[1])

      return [moIndex, m1Index]
    }else{
      return [field[0], field[1]]
    }
  }

  isInteger(str) {
    return /^\+?\d+$/.test(str);
  }

  dayOfWeek() {
    let sentence = [];
    let self = this;
    let cronInputArray = this.cronInputArray;
    let dayOfMonth = cronInputArray[2];
    let month = cronInputArray[3];
    let week = this.cronInputArray[4];

    if (
      week &&
      fieldValidator.validateDayofWeekAndDayofMonth(
        week,
        dayOfMonth,
        this.parserType
      )
    ) {
      if (week.includes("/")) {
        let dayOfWeek = week.split("/");

        if (
          fieldValidator.validateDayOfWeek(dayOfWeek[0]) &&
          fieldValidator.validateDayOfWeek(dayOfWeek[1])
        ) {
          sentence.push(`every ${fieldConvertor.getDayOfWeekIndex(
            dayOfWeek[1]
          )} day of the week,\
											   ${fieldConvertor.convertToDayOfWeek(
                           dayOfWeek[1]
                         )} through ${fieldConvertor.convertToDayOfWeek(
            "SAT"
          )}`);
        } else if (
          dayOfWeek[0] === "*" &&
          fieldValidator.validateDayOfWeek(dayOfWeek[1])
        ) {
          sentence.push(
            `every ${fieldConvertor.convertToDayOfWeek(dayOfWeek[1])}`
          );
        } else {
          self.pushErrors("invalid week");
        }
      } else if (week.includes("-")) {
        let dayOfWeek = week.split("-");

        if (
          fieldValidator.validateDayOfWeek(dayOfWeek[0]) &&
          fieldValidator.validateDayOfWeek(dayOfWeek[1]) &&
          (self.validateRange(self.getRange(dayOfWeek, days)))

        ) {
          sentence.push(
            `every ${fieldConvertor.convertToDayOfWeek(
              dayOfWeek[0]
            )} through ${fieldConvertor.convertToDayOfWeek(dayOfWeek[1])}`
          );
        } else {
          self.pushErrors("invalid week");
        }
      } else if (week.includes(",")) {
        let dayOfWeek = week.split(",");

        dayOfWeek.forEach(function (element, index) {
          if (
            fieldValidator.validateDayOfWeek(element) &&
            index < dayOfWeek.length - 2
          ) {
            sentence.push(`${fieldConvertor.convertToDayOfWeek(element)},`);
          } else if (
            fieldValidator.validateDayOfWeek(element) &&
            index < dayOfWeek.length - 1
          ) {
            sentence.push(`${fieldConvertor.convertToDayOfWeek(element)}`);
          } else if (
            fieldValidator.validateDayOfWeek(element) &&
            index === dayOfWeek.length - 1
          ) {
            sentence.push(`and ${fieldConvertor.convertToDayOfWeek(element)}`);
          } else {
            self.pushErrors("invalid week");
          }
        });
      } else if (week.includes("#")) {
        let dayOfWeek = week.split("#");

        if (
          fieldValidator.validateDayOfWeek(dayOfWeek[0]) &&
          fieldValidator.validateDayOfWeek(dayOfWeek[1])
        ) {
          sentence.push(
            `every ${fieldConvertor.ordinalSuffixOf(
              dayOfWeek[1]
            )} ${fieldConvertor.convertToDayOfWeek(dayOfWeek[0])} of the month`
          );
        } else {
          self.pushErrors("invalid day of week");
        }
      } else if (week.includes("L")) {
        sentence.push(self.lastDayOfWeek(week));
      } else if (fieldValidator.validateDayOfWeek(week)) {
        sentence.push(`only on ${fieldConvertor.convertToDayOfWeek(week)}`);
      } else if (
        week === "?" &&
        !this.generatedString.join("").includes("every") &&
        !this.generatedString.join("").includes("day") &&
        !fieldValidator.validateMonth(month)
      ) {
        sentence.push("every day");
      } else if (week === "*") {
      } else if (week === "?") {
      } else {
        self.pushErrors("invalid day of week");
      }
    } else {
      this.errors.push("Invalid Expression");
    }

    return sentence.join(" ");
  }

  parseYear() {
    let sentence = [];
    let year = this.cronInputArray[5];
    let self = this;

    if (year) {
      if (year === "*") {
      } else if (year.includes("-")) {
        let years = year.split("-");
        if (
          fieldValidator.validateYear(years[0]) &&
          fieldValidator.validateYear(years[1]) &&
          (self.validateRange(years))
        ) {
          sentence.push(`during the years ${years[0]} to ${years[1]}`);
        } else {
          self.pushErrors("invalid year");
        }
      } else if (year.includes(",")) {
        let years = year.split(",");

        years.forEach(function (element, index) {
          if (
            fieldValidator.validateYear(element) &&
            index < years.length - 2
          ) {
            sentence.push(`only the year ${element},`);
          } else if (
            fieldValidator.validateYear(element) &&
            index < years.length - 1
          ) {
            sentence.push(`${element}`);
          } else if (
            fieldValidator.validateYear(element) &&
            index === years.length - 1
          ) {
            sentence.push(`and ${element}`);
          }else {
						self.pushErrors("invalid year");
					}
        });
      } else if (fieldValidator.validateYear(year)) {
        sentence.push(`only in year ${year}`);
      } else {
        self.pushErrors("invalid year");
      }
    }

    return sentence.join(" ");
  }

  lastDayOfMonth(_lastDayOfMonth) {
    let sentence = [];

    if (_lastDayOfMonth.includes("-")) {
      let dayOfMonth = _lastDayOfMonth.split("-");

      if (dayOfMonth.length === 2 && parseInt(dayOfMonth[1])) {
        sentence.push(
          `on the ${fieldConvertor.ordinalSuffixOf(
            dayOfMonth[1]
          )} last day of every month`
        );
      }
    } else if (_lastDayOfMonth === "L") {
      sentence.push("on the last day of every month");
    }
    return sentence.join("");
  }

  lastDayOfWeek(lastDay) {
    let sentence = [];

    if (lastDay.length === 2) {
      sentence.push(
        `last ${fieldConvertor.convertToDayOfWeek(lastDay[0])} of the month`
      );
    } else {
      sentence.push(
        `last ${fieldConvertor.convertToDayOfWeek("SAT")} of the month`
      );
    }

    return sentence.join("");
  }
}
