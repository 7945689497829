import { monthMapping, dayMapping, monthName, days } from "../constants.js";

export var FieldConvertor = {
  convertHour(hours, minute = "00") {
    let self = this;
    let minuteFi = parseInt(minute) < 10 || minute === "0" ? "0" + parseInt(minute) : minute
    return `${self.convertHourFormat(parseInt(hours)).hour}:${minuteFi} ${
      self.convertHourFormat(parseInt(hours)).format
    }`;
  },

  convertHourFormat(hours) {
    return hours < 12
      ? {
          hour: hours,
          format: "AM",
        }
      : {
          hour: hours % 12 || 12,
          format: "PM",
        };
  },

  convertToMonth(month) {
    if (monthMapping[month] && monthMapping[month].length) {
      return monthName[monthMapping[month]];
    } else if (Object.values(monthMapping).indexOf(month) > -1) {
      return monthName[month];
    }
  },

  convertToDayOfWeek(week) {
    return dayMapping[week] || Object.values(dayMapping)[parseInt(week) - 1];
  },

  getDayOfWeekIndex(week) {
    let self = this;
    if (parseInt(week)) {
      return self.ordinalSuffixOf(week);
    } else {
      return self.ordinalSuffixOf(
        Object.keys(days).find((key) => days[key] === week)
      );
    }
  },

  getMonthIndex(month) {
    let self = this;
    if (parseInt(month)) {
      return self.ordinalSuffixOf(month);
    } else {
      return self.ordinalSuffixOf(
        Object.keys(monthMapping).find((key) => monthMapping[key] === month)
      );
    }
  },

  amPmIdentifire(hour) {
    let _hour = parseInt(hour);
    return _hour < 12 ? "AM" : "PM";
  },

  ordinalSuffixOf(num) {
    let num2 = num % 10,
      num3 = num % 100;

    if (num2 === 1 && num3 !== 11) {
      return num + "st";
    }

    if (num2 === 2 && num3 !== 12) {
      return num2 + "nd";
    }

    if (num2 === 3 && num3 !== 13) {
      return num3 + "rd";
    }

    return num + "th";
  },
};
