import { awsCronDefaultStart, wrongInputs } from "../constants.js";
import CronParser from "./cronParser.js";

export default class UnixTranslator extends CronParser {
  constructor(cronInput) {
    super(cronInput, "unix");
    this.generatedString = [];
  }

  pushToGeneratedString(formatString) {
    this.generatedString.push(formatString);
  }

  unixCronConvertor() {
    var self = this;

    if (this.cronInputArray.length === 5 || this.cronInputArray.length === 6) {
      self.pushToGeneratedString(awsCronDefaultStart);
      self.pushToGeneratedString(self.parseMinutes());
      self.pushToGeneratedString(self.parseHours());
      self.pushToGeneratedString(self.parseDayOfMonth());
      self.pushToGeneratedString(self.parseMonth());
      self.pushToGeneratedString(self.dayOfWeek());
      // parse year is optional in unix cron
      self.pushToGeneratedString(self.parseYear());
    } else {
      this.errors.push(wrongInputs);
    }

    return this.generatedString.join(" ").replace(/\s+/g, " ").trim();
  }
}
