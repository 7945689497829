import { awsCronDefaultStart, wrongInputs } from "../constants.js";
import CronParser from "./cronParser.js";

export default class AwsTranslator extends CronParser {
  constructor(cronInput) {
    super(cronInput, "aws");
    this.generatedString = [];
  }

  pushToGeneratedString(formatString) {
    this.generatedString.push(formatString);
  }

  awsCronConvertor() {
    var self = this;

    if (this.cronInputArray.length === 6) {
      self.pushToGeneratedString(awsCronDefaultStart);
      self.pushToGeneratedString(self.parseMinutes());
      self.pushToGeneratedString(self.parseHours());
      self.pushToGeneratedString(self.parseDayOfMonth());
      self.pushToGeneratedString(self.parseMonth());
      self.pushToGeneratedString(self.dayOfWeek());
      self.pushToGeneratedString(self.parseYear());
    } else {
      this.errors.push(wrongInputs);
    }

    return this.generatedString.join(" ").replace(/\s+/g, " ").trim();
  }
}
