import AwsTranslator from "./awsTranslator.js";
import UnixTranslator from "./unixTranslator";

export function translate(cronDetails) {
  const { cronType, cronInput } = cronDetails;
  let unixCron = new UnixTranslator(cronInput);

  if (cronInput) {
    switch (cronType) {
      case "aws_cron":
        let awsCron = new AwsTranslator(cronInput);
        return {
          output: awsCron.awsCronConvertor(),
          errors: awsCron.getErrors(),
        };
      case "unix_cron":
        return {
          output: unixCron.unixCronConvertor(),
          errors: unixCron.getErrors(),
        };
      case "google_cron":
        // as google cron similar as unix cron
        // let unixCron = new UnixTranslator(cronInput);
        return {
          output: unixCron.unixCronConvertor(),
          errors: unixCron.getErrors(),
        };
      default:
        // statements_def
        break;
    }
  }
}
