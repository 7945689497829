import { monthMapping, dayMapping, wildCards } from "../constants.js";

export var FieldValidator = {
  validateMinute(minute) {
    let _minute = parseInt(minute);
    let self = this;
    if (!self.havingWildCards(minute)) {
      if (
        minute === "0" ||
        (this.isInteger(minute) && _minute && _minute <= 59 && _minute >= 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  validateHours(hours) {
    let _hours = parseInt(hours);
    if (!wildCards.includes(hours)) {
      if (
        hours === "0" ||
        (this.isInteger(hours) && _hours <= 23 && _hours >= 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  validateDayOfMonth(dayOfMonths) {
    let dayOfMonth = parseInt(dayOfMonths);
    if (!wildCards.includes(dayOfMonth) && dayOfMonth) {
      if (
        dayOfMonth &&
        this.isInteger(dayOfMonths) &&
        dayOfMonth <= 31 &&
        dayOfMonth >= 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  validateMonth(month) {
    if (month && !wildCards.includes(month)) {
      if (
        (this.isInteger(month) && monthMapping[month]) ||
        Object.values(monthMapping).indexOf(month) > -1
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  validateDayOfWeek(week) {
    if (week && !wildCards.includes(week)) {
      if (
        dayMapping[week] ||
        (this.isInteger(week) && Object.values(dayMapping)[parseInt(week) - 1])
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  validateYear(year) {
    if (year && !wildCards.includes(year)) {
      if (
        this.isInteger(year) &&
        parseInt(year) <= 2199 &&
        parseInt(year) >= 1970
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  validateDayofWeekAndDayofMonth(dayOfWeek, dayOfMonth, parserType) {
    if (parserType === "aws")
      return (
        (dayOfWeek && dayOfMonth === "?") || (dayOfMonth && dayOfWeek === "?")
      );
    return true;
  },

  validateBothHour(hours) {
    return this.validateHours(hours[0]) && this.validateHours(hours[1]);
  },

  havingWildCards(str) {
    return str.split("").some((element) => wildCards.includes(element));
  },

  validateBothMinutes(minutes) {
    return this.validateMinute(minutes[0]) && this.validateMinute(minutes[1]);
  },

  isInteger(str) {
    return /^\+?\d+$/.test(str);
  },
};
