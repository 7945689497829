import React from "react";

class CronTitle extends React.Component {
  render() {
    return (
      <>
        <div className="logo-container">
          <span className="logo-first-word">
            <span className="cron-word">Crons</span>former{" "}
          </span>
          {/* <span className="logo-second-word">Former </span> */}
        <p className="about">The quick and simple editor for cron schedule expressions</p>

        </div>
      </>
    );
  }
}
export default CronTitle;
