import React from "react";

class CronOutput extends React.Component {
  render() {
    const { result, errors } = this.props;

    return (
      <div className="cron-result">
        {errors.length > 0 ? (
          <span className="error">Errors: {errors}</span>
        ) : (
          <span className="success">"{result}"</span>
        )}
      </div>
    );
  }
}
export default CronOutput;
