import React from "react";
import CronRoute from "./components/CronRoute.js";

function App() {
  return (
    <CronRoute />
  );
}

export default App;
