/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

class SocialLinks extends React.Component {
  render() {
    return (
      <footer>
        <div className="social-links">
          <a href="https://github.com/scalereal" target="_blank">
            <svg
              width="134px"
              height="135px"
              viewBox="0 0 134 135"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Github</title>
              <desc>Created with Sketch.</desc>
              <g
                id="Github"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="Githun" transform="translate(4.000000, 4.000000)">
                  <ellipse
                    id="Oval-Copy-4"
                    fill="#126647"
                    cx="63"
                    cy="62.5"
                    rx="63"
                    ry="62.5"
                  ></ellipse>
                  <g
                    id="github-image"
                    transform="translate(19.000000, 35.000000)"
                    fill="#00C47C"
                  >
                    <path
                      d="M24.3478261,86.9483846 C27.2660687,87.4685497 28.4735626,83.7003232 28.4735626,82.2335513 C28.4735626,80.9214231 28.4249252,77.4442833 28.4006065,72.8377759 C12.16545,76.1696444 8.74137866,65.4242515 8.74137866,65.4242515 C6.08577786,59.046371 2.2482888,57.3406043 2.2482888,57.3406043 C-3.0385941,53.9150124 2.65684277,53.985305 2.65684277,53.985305 C8.51764672,54.3695711 11.5963927,59.6790043 11.5963927,59.6790043 C16.8005921,68.1281728 25.258632,65.6866771 28.595156,64.2761393 C29.1204397,60.7005898 30.6233347,58.2684664 32.2915967,56.8860456 C19.3297357,55.5036248 5.70640632,50.7518462 5.70640632,29.57972 C5.70640632,23.5486163 7.96804436,18.6187631 11.7131224,14.752671 C11.0565178,13.3561917 9.08670403,7.73747113 12.2238149,0.127127409 C12.2238149,0.127127409 17.1118713,-1.35370302 28.2741493,5.79270965 C32.9433376,4.56493252 37.90435,3.95573013 42.8653625,3.9276131 C47.826375,3.95573013 52.7873875,4.56493252 57.4565757,5.79270965 C68.5458977,-1.35370302 73.4339541,0.127127409 73.4339541,0.127127409 C76.5710649,7.73747113 74.6012511,13.3561917 74.0176026,14.752671 C77.738362,18.6187631 80,23.5486163 80,29.57972 C80,50.8080802 66.3572157,55.480194 53.371036,56.8391839 C55.4138058,58.4980889 57.3106635,61.8861914 57.3106635,67.0644117 C57.3106635,74.4591915 57.2377075,80.4012579 57.2377075,82.1960619 C57.2377075,83.6440891 59.617497,87.5013529 62.6086957,86.9483846 C62.1352918,87.114012 41.7391304,95.643223 24.3478261,86.9483846 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <a
            href="https://in.linkedin.com/company/scalereal-technologies-pvt-ltd"
            target="_blank"
          >
            <svg
              width="134px"
              height="135px"
              viewBox="0 0 134 135"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>LinkedIn</title>
              <desc>Created with Sketch.</desc>
              <g
                id="LinkedIn"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="Linkedin" transform="translate(3.000000, 4.000000)">
                  <circle
                    id="Oval"
                    fill-opacity="0.719487544"
                    fill="#4A4F5A"
                    cx="64"
                    cy="64"
                    r="64"
                  ></circle>
                  <path
                    d="M44.3000667,39 C41.3786688,39 39.0000741,41.4042419 39.0000741,44.3571429 C39.0000741,47.3100438 41.3786688,49.7142857 44.3000667,49.7142857 C47.2214645,49.7142857 49.6000592,47.3100438 49.6000592,44.3571429 C49.6000592,41.4042419 47.2214645,39 44.3000667,39 Z M78.2592785,53.2857143 C73.476751,53.2857143 70.5995316,55.517814 68.4444774,57.8077278 L68.4444774,55.1330049 C68.4444433,54.810601 68.1327488,54.5172734 67.7901573,54.5172414 L57.3210361,54.5172414 C56.9784446,54.5172734 56.666716,54.810601 56.666716,55.1330049 L56.666716,88.3842365 C56.666716,88.7066404 56.9784446,89 57.3210361,89 L68.4444774,89 C68.7870689,89 69.0987634,88.7066404 69.0987974,88.3842365 L69.0987974,69.91133 C69.0987974,68.2280172 69.6535071,66.6472906 70.5096751,65.5240148 C71.3658431,64.4007389 72.4814032,63.7536946 73.6790379,63.7536946 L74.9876781,63.7536946 C76.1853128,63.7536946 77.3008729,64.4007389 78.1570409,65.5240148 C79.0132089,66.6472906 79.5679186,68.2280172 79.5679186,69.91133 L79.5679186,88.3842365 C79.5679526,88.7066404 79.8796472,89 80.2222387,89 L91.3456799,89 C91.6882714,89 92,88.7066404 92,88.3842365 L92,66.8325123 C92,62.8060961 91.1792189,59.4063547 88.9942172,56.9995382 C86.8092154,54.5927217 83.3084041,53.2857143 78.2592785,53.2857143 Z M39.4534362,56.8571429 C39.1976983,56.9073481 38.9955629,57.1633046 39.0000741,57.4311235 L39.0000741,88.4260204 C39.0000741,88.7265469 39.2659383,89 39.5580406,89 L49.0421693,89 C49.3342716,89 49.6000592,88.7265469 49.6000592,88.4260204 L49.6000592,57.4311235 C49.6000592,57.1305969 49.3342716,56.8571429 49.0421693,56.8571429 C45.8493117,56.8571429 42.6382479,56.8571429 39.4534362,56.8571429 Z"
                    id="LinkedIn"
                    fill="#B3B3B3"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
          <a href="https://www.facebook.com/ScaleReal/" target="_blank">
            <svg
              width="134px"
              height="135px"
              viewBox="0 0 134 135"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Facebook</title>
              <desc>Created with Sketch.</desc>
              <g
                id="Facebook"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="FB" transform="translate(3.000000, 3.000000)">
                  <circle
                    id="Oval-Copy"
                    fill="#004E8B"
                    cx="64"
                    cy="64"
                    r="64"
                  ></circle>
                  <g
                    id="facebook"
                    transform="translate(45.000000, 36.000000)"
                    fill="#1196FF"
                    fill-rule="nonzero"
                  >
                    <path
                      d="M26.9752521,10.1285417 L33,10.1285417 L33,0.429541667 C31.9605866,0.297375 28.3858845,0 24.2227314,0 C15.5362053,0 9.58570119,5.05029167 9.58570119,14.3324583 L9.58570119,22.875 L0,22.875 L0,33.71775 L9.58570119,33.71775 L9.58570119,61 L21.3382218,61 L21.3382218,33.7202917 L30.5362053,33.7202917 L31.9963336,22.8775417 L21.335472,22.8775417 L21.335472,15.4075833 C21.3382218,12.2737083 22.2511457,10.1285417 26.9752521,10.1285417 L26.9752521,10.1285417 Z"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <a
            href="https://twitter.com/ScaleReal"
            target="_blank"
          >
            <svg
              width="134px"
              height="135px"
              viewBox="0 0 134 135"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Twitter</title>
              <desc>Created with Sketch.</desc>
              <g
                id="Tweeter"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="Tweet" transform="translate(1.000000, 2.000000)">
                  <circle
                    id="Oval-Copy-2"
                    fill="#664F49"
                    cx="66"
                    cy="66"
                    r="66"
                  ></circle>
                  <path
                    d="M97,47.681008 C94.8294923,48.645878 92.4968708,49.295136 90.0471684,49.5927119 C92.5419015,48.0867937 94.4692419,45.7151982 95.3698678,42.8837133 C93.0282406,44.2724028 90.4344381,45.2823595 87.6785219,45.8234078 C85.4719891,43.4698484 82.3288055,42 78.8433829,42 C72.1607379,42 66.7389714,47.4285188 66.7389714,54.1194813 C66.7389714,55.0663176 66.8470465,55.9951154 67.0541911,56.8788287 C56.9941986,56.3738503 48.0780038,51.5404857 42.1068539,44.2092822 C41.0621279,46.0037572 40.467716,48.0867937 40.467716,50.3141096 C40.467716,54.5162501 42.6021987,58.2224313 45.8534574,60.3956413 C43.8630749,60.3325208 41.9987788,59.7914725 40.3686466,58.8807062 C40.3686466,58.9348121 40.3686466,58.9798989 40.3686466,59.0340049 C40.3686466,64.904377 44.5385448,69.8008644 50.0773921,70.9190309 C49.0596854,71.198573 47.9969481,71.3428526 46.8891777,71.3428526 C46.1056326,71.3428526 45.3491069,71.2707128 44.6105949,71.1264333 C46.1506634,75.9327449 50.6177676,79.4495598 55.9134476,79.5397334 C51.7795767,82.7950399 46.5559467,84.7247799 40.8820027,84.7247799 C39.9003211,84.7247799 38.9456566,84.6706739 38,84.5624643 C43.3497187,87.9981225 49.7171417,90 56.5528925,90 C78.8163635,90 90.992825,71.5322189 90.992825,55.5262069 C90.992825,54.9941778 90.9838193,54.4711633 90.9568,53.9481511 C93.3254465,52.2528665 95.3788735,50.1157241 97,47.681008"
                    id="Path"
                    fill="#FF7551"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/scalerealhq/"
            target="_blank"
          >
            <svg
              width="134px"
              height="135px"
              viewBox="0 0 134 135"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Instagram</title>
              <desc>Created with Sketch.</desc>
              <g
                id="Insta"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="instagram" transform="translate(3.000000, 4.000000)">
                  <circle
                    id="Oval-Copy-3"
                    fill-opacity="0.455364948"
                    fill="#F60A39"
                    cx="64"
                    cy="64"
                    r="64"
                  ></circle>
                  <path
                    d="M85.6503906,37 L45.3496094,37 C40.740625,37 37,40.740625 37,45.3496094 L37,85.6503906 C37,90.259375 40.740625,94 45.3496094,94 L85.6503906,94 C90.259375,94 94,90.259375 94,85.6503906 L94,45.3496094 C94,40.740625 90.259375,37 85.6503906,37 Z"
                    id="Path"
                    stroke="#FF8AA1"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M66,81 C57.7334444,81 51,74.2665556 51,66 C51,57.7332222 57.7334444,51 66,51 C74.2665556,51 81,57.7332222 81,66 C81,74.2665556 74.2665556,81 66,81 Z"
                    id="Path"
                    stroke="#FF8AA1"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M84.5,51 C82.575,51 81,49.425 81,47.5 C81,45.575 82.575,44 84.5,44 C86.425,44 88,45.575 88,47.5 C88,49.425 86.425,51 84.5,51 Z"
                    id="Path"
                    fill="#FF8AA1"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
        </div>

        <div className="copyrights">
          © {new Date().getFullYear()} Crons Former. Powered by{" "}
          <a
            href="https://scalereal.com/"
            target="_blank"
            className="company-link"
          >
            ScaleReal.
          </a>
        </div>
      </footer>
    );
  }
}
export default SocialLinks;
