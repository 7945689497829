import React from "react";
import { Input, Button, Col } from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { cronTypes, wildCardsTypes, designWildCardsTypes } from "../constants";
import {
  randomYear,
  randomDayOfWeek,
  randomDayOfMonth,
  randomHours,
  randomMinute,
  randomMonth,
} from "../constants.js";

class CronInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      dropdownValue: cronTypes[0],
      inputVal: this.props.inputValue,
      activeWildCard: this.props.syntax,
    };
  }

  toggle = () => {
    const { dropdownOpen } = this.state;

    this.setState({
      dropdownOpen: !dropdownOpen,
    });
  };

  handleDropDownItem = (type) => {
    const { cronInputHandler } = this.props;

    cronInputHandler(type);
  };

  generateRandomInput = () => {
    const { randomExpHandler } = this.props;
    let min = randomMinute[parseInt(Math.random() * randomMinute.length)];
    let hour = randomHours[parseInt(Math.random() * randomHours.length)];
    let dayOfMonth =
      randomDayOfMonth[parseInt(Math.random() * randomDayOfMonth.length)];
    let month = randomMonth[parseInt(Math.random() * randomMonth.length)];
    let week =
      dayOfMonth === "?" || this.props.selectValue.value === "unix_cron"
        ? randomDayOfWeek[parseInt(Math.random() * randomDayOfWeek.length)]
        : "?";
    let year = randomYear[parseInt(Math.random() * randomYear.length)];

    let randomExp = `${min} ${hour} ${dayOfMonth} ${month} ${week} ${year}`;

    randomExpHandler(randomExp);
  };

  handleInputCursor = (part, index) => {
    let input = document.getElementById("cron-input");
    this.props.setActiveKeySyntax(part);

    if (input) {
      let self = this;
      let splitInput = input.value.split(" ");
      let currentSelected = splitInput[index];
      let startIndex;

      this.setState({
        activeWildCard: part,
      });

      for (let i = 0; i < index; i++) {
        splitInput[i] = self.generate(splitInput[i].length);
      }
      currentSelected = splitInput[index];
      startIndex = splitInput.join(" ").indexOf(currentSelected);

      if (currentSelected.length) {
        input.setSelectionRange(
          startIndex,
          startIndex + currentSelected.length
        );
        input.focus();
      }
    }
  };

  generate = (n) => {
    var randStr = "";

    for (let i = 0; i < n; i++) {
      randStr += "#";
    }

    return randStr;
  };

  handleCopyButton = () => {
    var copyText = document.getElementById("cron-input");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  render() {
    const { dropdownOpen, activeWildCard } = this.state;
    const {
      selectValue,
      onKeyUpHandler,
      inputValue,
      onChangeHandler,
      syntax,
      errors,
    } = this.props;
    let that = this;

    return (
      <div>
        <div className="input-group">
          <Col md="3" className="padding-0">
            <span className="cron-type">Select CRON Type</span>
          </Col>
          <Col md="6" className="padding-0">
            <Dropdown isOpen={dropdownOpen} toggle={that.toggle}>
              <DropdownToggle className="my-dropdown" caret>
                <span>{selectValue.label}</span>
              </DropdownToggle>

              <DropdownMenu right>
                {cronTypes.map((type, index) => {
                  return (
                    <DropdownItem
                      onClick={() => that.handleDropDownItem(type)}
                      key={type + index}
                      value={index}
                      className={type.value === selectValue.value ? "active" : "dsd"}
                    >
                      <span>{type.label}</span>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col md="3">
            <button className="copy-btn" onClick={this.handleCopyButton}>
              Copy text value
            </button>
          </Col>
        </div>
        <div className="input-group">
          <Col md="12">
            <div
              className={
                errors.length > 0 ? "expression error" : "expression standard"
              }
            >
              <Input
                onChange={onChangeHandler}
                onKeyUp={onKeyUpHandler}
                value={inputValue}
                onMouseUp={onKeyUpHandler}
                id="cron-input"
              />
            </div>
          </Col>
        </div>
        <div className="input-info-wrapper">
          <div className="wildcards">
            {wildCardsTypes.map((part, index) => (
              <Button
                className={
                  (!syntax && activeWildCard === part) || part === syntax
                    ? "active wildcard"
                    : "wildcard"
                }
                onClick={(e) => this.handleInputCursor(part, index)}
                key={part + index}
                value={index}
              >
                <span>{designWildCardsTypes[part]}</span>
              </Button>
            ))}

            <Button
              outline
              color="danger"
              className="random-button"
              onClick={this.generateRandomInput}
            >
              Random
            </Button>
          </div>
        </div>


        <div className="mobile-views">
            <button className="copy-btn" onClick={this.handleCopyButton}>
              Copy text value
            </button>

            <Button
              outline
              color="danger"
              className="random-button"
              onClick={this.generateRandomInput}
            >
              Random
            </Button>
          </div>
      </div>
    );
  }
}
export default CronInput;
