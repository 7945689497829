import React from "react";
import CronCosole from "./CronConsole.js";
import { BrowserRouter, Switch, Route } from "react-router-dom";

class CronRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      queryParams: "",
    };
  }

  handleQueryParams = (queryParams = "") => {
    this.setState({
      queryParams: queryParams,
    });
  };

  render() {
    return (
      <>
        <div className="App">
          <BrowserRouter>
            <Switch>
              <Route
                path={`/${this.state.queryParams}`}
                component={CronCosole}
                queryParamsHandler={this.handleQueryParams}
              />
            </Switch>
          </BrowserRouter>
        </div>
      </>
    );
  }
}
export default CronRoute;
